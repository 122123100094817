import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Home from './Home';
import Menu from './Menu';
import OverParos from './OverParos';
import Contact from './Contact';

const App = () => {
  return (

      <Router>
        <Routes>
          {/* Define routes for different pages */}
            <Route path="/" element={<Home />} />
            <Route path="/Menu" element={<Menu />} />
            <Route path="/OverParos" element={<OverParos />} />
            <Route path="/Contact" element={<Contact />} />
        </Routes>
      </Router>
  );
}

export default App;
