import './Home.css';
import './Header.css';
import './Footer.css';
import './Fonts/style.css'
import phone from './Images/phone.png';
import logo from './Images/Primair-logo-Paros.png';
import binnen from './Images/Grieks-Restaurant-Paros-binnen.jpg';
import buiten from './Images/Grieks-Restaurant-Paros-buitenplaats.jpg';
import terras from './Images/Grieks-Restaurant-Paros-Terras.jpg';
import React, {useState, useEffect} from "react";
import { Helmet } from 'react-helmet';


import { initializeApp } from "firebase/app";
import firebaseConfig from './firebaseConfig.js';
import { getDatabase, ref, set, onValue } from "firebase/database";

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const Menu = () => {

    const [kvList, setKvList] = useState([]);
    const [soepList, setSoepList] = useState([]);
    const [kmList, setKMList] = useState([]);
    const [mezList, setMezList] = useState([]);
    const [ovenList, setOvenList] = useState([]);
    const [specList, setSpecList] = useState([]);
    const [visList, setVisList] = useState([]);
    const [vleesList, setVleesList] = useState([]);
    const [grillList, setGrillList] = useState([]);
    const [extraList, setExtraList] = useState([]);
    const [wvList, setWvList] = useState([]);

    useEffect(() => {
        const database = getDatabase(); // Initialize Firebase database reference
        const databaseRefkv = ref(database, 'KV');

        const unsubscribekv = onValue(databaseRefkv, (snapshot) => {
            const fetchedKvList = Object.values(snapshot.val() || {}); // Handle potential null value
            setKvList(fetchedKvList);
        });

        const databaseRefwv = ref(database, 'WV');

        const unsubscribewv = onValue(databaseRefwv, (snapshot) => {
            const fetchedWvList = Object.values(snapshot.val() || {}); // Handle potential null value
            setWvList(fetchedWvList);
        });

        const databaseRefsoep = ref(database, 'Soep');

        const unsubscribesoep = onValue(databaseRefsoep, (snapshot) => {
            const fetchedSoepList = Object.values(snapshot.val() || {});
            setSoepList(fetchedSoepList);
        });

        const databaseRefkm = ref(database, 'KM');

        const unsubscribekm = onValue(databaseRefkm, (snapshot) => {
            const fetchedKMList = Object.values(snapshot.val() || {});
            setKMList(fetchedKMList);
        });

        const databaseRefmezes = ref(database, 'Mezes');

        const unsubscribemezes = onValue(databaseRefmezes, (snapshot) => {
            const fetchedmezesList = Object.values(snapshot.val() || {}); // Handle potential null value
            setMezList(fetchedmezesList);
        });

        const databaseRefOven = ref(database, 'Oven');

        const unsubscribeoven = onValue(databaseRefOven, (snapshot) => {
            const fetchedOvenList = Object.values(snapshot.val() || {});
            setOvenList(fetchedOvenList);
        });

        const databaseRefspec = ref(database, 'Spec');

        const unsubscribespec = onValue(databaseRefspec, (snapshot) => {
            const fetchedSpecList = Object.values(snapshot.val() || {});
            setSpecList(fetchedSpecList);
        });

        const databaseRefvis = ref(database, 'Vis');

        const unsubscribevis = onValue(databaseRefvis, (snapshot) => {
            const fetchedvisList = Object.values(snapshot.val() || {}); // Handle potential null value
            setVisList(fetchedvisList);
        });

        const databaseRefVlees = ref(database, 'Vlees');

        const unsubscribevlees = onValue(databaseRefVlees, (snapshot) => {
            const fetchedVleesList = Object.values(snapshot.val() || {});
            setVleesList(fetchedVleesList);
        });

        const databaseRefGrill = ref(database, 'Grill');

        const unsubscribegrill = onValue(databaseRefGrill, (snapshot) => {
            const fetchedGrillList = Object.values(snapshot.val() || {});
            setGrillList(fetchedGrillList);
        });

        const databaseRefExtra = ref(database, 'Extra');

        const unsubscribeextra = onValue(databaseRefExtra, (snapshot) => {
            const fetchedExtraList = Object.values(snapshot.val() || {});
            setExtraList(fetchedExtraList);
        });

        return () => unsubscribekv() + unsubscribewv() + unsubscribesoep() + unsubscribekm() + unsubscribespec() + unsubscribeoven() + unsubscribemezes() + unsubscribeextra() + unsubscribevis() + unsubscribevlees() + unsubscribegrill();
    }, []);


    return (
        <div className="App">
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="robots" content="index, follow"/>
                <meta name="description" content="wat onze gasten van paros vinden. Typisch Grieks restaurant met bijzonder vers en perfect bereide schotels. Ruim voldoende op je bord. Het gegrilde vlees ..."/>
                <title>Grieks Restaurant Paros | Het restaurant waar je écht even kunt relaxen.</title>
                <link rel="icon" href="./Images/Primair_logo_Paros.ico" type="image/x-icon"/>
                <link rel="apple-touch-icon" sizes="180x180" href="Images/Primair-logo-Paros.png"/>
                <link rel="shortlink" href="https://restaurant-paros-echt.nl/"/>
                <link rel="canonical" href="https://restaurant-paros-echt.nl/"/>
                <meta name="revisit-after" content="7 days"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="stylesheet" href="Menu.css"/>

            </Helmet>
            <header>
                <div className="top-header">
                    <div className="width-div">
                        <p>
                            <img alt="Grieks Restaurant Paros" src={phone} width="12px"/>
                            <span className="top-header-span">
                                Reserveer je plek via
                                <a href="tel:0475 487 814"> 0475 487 814</a>
                            </span>
                        </p>
                    </div>
                </div>
                <div className="main-header">
                    <nav>
                        <ul className="nav">
                            <li>
                                <a aria-current="page" href="./">home</a>
                            </li>
                            <li>
                                <a href="/OverParos">over paros</a>
                            </li>
                            <li>
                                <a href="./">
                                    <img width="60px" alt="Grieks Restaurant Paros" id="logo"
                                         src={logo}/>
                                </a>
                            </li>
                            <li>
                                <a href="./Menu">menu</a>
                            </li>
                            <li>
                                <a href="./Contact">contact</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            <div class="main">
                <div class="snowfall" id="snowfall"></div>
                <div class="top-space"></div>
                <div class="space"></div>
                <p>
                    Ga je voor <strong>de traditionele moussaka</strong> of een gerecht met <strong>gyros</strong>? Misschien
                    kies je wel voor een overheerlijke <strong>visschotel</strong>. Ervaar even het ultieme vakantiegevoel door
                    de combinatie van een Griekse maaltijd en het sfeervolle restaurant. Of neem tijdens de zonnige dagen plaats
                    in onze mediterrane buitentuin onder de druivenstruiken. Stel je eigen gerecht samen met de mooie producten
                    van onze kaart en blijf gerust uren na-borrelen met als afsluiter een traditioneel glaasje ouzo. <strong>Gia
                    mas</strong>!
                </p>
                <div class="space"></div>
                <div class="menu">
                    <div class="menu-left">
                        <div class="menu-box">
                            <h6 class="menu-title">Koude Voorgerechten</h6>
                            <ul id="koudv" class="menu-list">
                                {kvList.map((kv) => (
                                    <li class="menu-list-item" key={kv.naam}>  {/* Use a unique key based on a property */}
                                        <p class="gerecht">{kv.naam}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <span>{kv.prijs}</span></p>
                                        <p class="omschrijving"><i>{kv.beschrijving}</i></p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div class="menu-box">
                            <h6 class="menu-title">Warme Voorgerechten</h6>
                            <ul id="warmv" class="menu-list">
                                {wvList.map((wv) => (
                                    <li class="menu-list-item" key={wv.naam}>  {/* Use a unique key based on a property */}
                                        <p class="gerecht">{wv.naam}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <span>{wv.prijs}</span></p>
                                        <p class="omschrijving"><i>{wv.beschrijving}</i></p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div class="menu-box">
                            <h6 class="menu-title">Menu’s </h6>
                            <ul id="menu" class="menu-list">
                                {mezList.map((mez) => (
                                    <li class="menu-list-item" key={mez.naam}>  {/* Use a unique key based on a property */}
                                        <p class="gerecht">{mez.naam}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <span>{mez.prijs}</span></p>
                                        <p className="gerecht2">{mez.naam2}</p>
                                        <p class="omschrijving"><i>{mez.beschrijving}</i></p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div class="menu-box">
                            <h6 class="menu-title">Ovengerechten en stoofschotels</h6>
                            <ul id="oven" class="menu-list">
                                {ovenList.map((oven) => (
                                    <li class="menu-list-item" key={oven.naam}>  {/* Use a unique key based on a property */}
                                        <p class="gerecht">{oven.naam}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <span>{oven.prijs}</span></p>
                                        <p class="omschrijving"><i>{oven.beschrijving}</i></p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div class="menu-right">
                        <div class="menu-box">
                            <h6 class="menu-title">Soepen</h6>
                                <ul class="menu-list">
                                    {soepList.map((soep) => (
                                        <li key={soep.naam}>
                                            <p className="gerecht">{soep.naam}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}
                                                <span>{soep.prijs}</span></p>
                                        </li>
                                    ))}
                                </ul>
                        </div>
                        <div class="menu-box">
                            <h6 class="menu-title">Kindermenu’s</h6>
                            <ul id="kind" class="menu-list">
                                {kmList.map((km) => (
                                    <li key={km.naam}>
                                        <p className="gerecht">{km.naam}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}
                                            <span>{km.prijs}</span></p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div class="menu-box">
                            <h6 class="menu-title">Extra’s</h6>
                            <ul id="extra" class="menu-list">
                                {extraList.map((ex) => (
                                    <li class="menu-list-item" key={ex.naam}>  {/* Use a unique key based on a property */}
                                        <p class="gerecht">{ex.naam}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <span>{ex.prijs}</span></p>
                                        <p class="omschrijving"><i>{ex.beschrijving}</i></p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div class="menu-box">
                            <h6 class="menu-title">Visgerechten</h6>
                            <ul id="vis" class="menu-list">
                                {visList.map((vis) => (
                                    <li class="menu-list-item" key={vis.naam}>  {/* Use a unique key based on a property */}
                                        <p class="gerecht">{vis.naam}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <span>{vis.prijs}</span></p>
                                        <p class="omschrijving"><i>{vis.beschrijving}</i></p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div class="menu-box">
                            <h6 class="menu-title">Vleesgerechten</h6>
                            <ul id="vlees" class="menu-list">
                                {vleesList.map((vlees) => (
                                    <li class="menu-list-item" key={vlees.naam}>  {/* Use a unique key based on a property */}
                                        <p class="gerecht">{vlees.naam}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <span>{vlees.prijs}</span></p>
                                        <p class="omschrijving"><i>{vlees.beschrijving}</i></p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h1>
                                Greek food <br/>
                                is always <br/>
                                a great idea
                            </h1>
                        </div>
                        <div class="space"></div>
                    </div>
                </div>
                <div class="menu">
                    <div class="menu-left-2">
                        <div class="menu-box">
                            <h6 class="menu-title">vleesgerechten van de grill</h6>
                            <ul id="grill" class="menu-list">
                                {grillList.map((grill) => (
                                    <li class="menu-list-item" key={grill.naam}>  {/* Use a unique key based on a property */}
                                        <p class="gerecht">{grill.naam}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <span>{grill.prijs}</span></p>
                                        <p class="omschrijving"><i>{grill.beschrijving}</i></p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div class="menu-right-2">
                        <div class="menu-box">
                            <h6 class="menu-title">specialiteiten van paros</h6>
                            <ul id="special" class="menu-list">
                                {specList.map((spec) => (
                                    <li class="menu-list-item" key={spec.naam}>  {/* Use a unique key based on a property */}
                                        <p class="gerecht">{spec.naam}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <span>{spec.prijs}</span></p>
                                        <p class="omschrijving"><i>{spec.beschrijving}</i></p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h5 class="wjd-title">Wist je dat:</h5>
                            <p class="wjd-tekst">
                                Het is bij Grieks restaurant Paros ook mogelijk is om zelf een gerecht samen te stellen met de producten die op de kaart staan? Zie je twee gerechten en wil je hier een combinatie van? Dan is dat zeker mogelijk. Een leuk voorbeeld: onze vaste klant Will komt regelmatig genieten van de “Will schotel”. Op een dag besloot Will zelf een visschotel samen te stellen die hij tot op de dag van vandaag regelmatig bestelt. Het gerecht staat nu zelfs op de kaart! Wil jij ook je favoriete gerecht samenstellen, vraag dan gerust naar de mogelijkheden.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="space"></div>
                <div class="bottom-images">
                    <img alt="Grieks Restaurant Paros" src={binnen}/>
                    <img alt="Grieks Restaurant Paros" src={buiten}/>
                    <img alt="Grieks Restaurant Paros" src={terras}/>
                </div>
                <div class="space"></div>
            </div>
            <footer>
                <div className="main">
                    <div className="main-footer">
                        <div className="main-f-c">
                            <h4 className="footer-title">OPENINGSTIJDEN</h4>
                            <p>
                                Maandag: gesloten<br/>
                                Dinsdag: gesloten<br/>
                                Woensdag: 17:00 - 22:00<br/>
                                Donderdag: 17:00 - 22:00<br/>
                                Vrijdag: 17:00 - 22:00<br/>
                                Zaterdag: 17:00 - 22:00<br/>
                                Zondag: 15:00 - 22:00<br/>
                            </p>
                        </div>
                        <div className="main-f-c">
                            <h4 className="footer-title">CONTACT</h4>
                            <p>
                                TEL: 0475 487 814
                            </p>
                        </div>
                        <div className="main-f-c">
                            <h4 className="footer-title">BEREIKBAARHEID</h4>
                            <p>
                                Grieks restaurant Paros is gemakkelijk bereikbaar met de auto, fiets of het openbaar
                                vervoer. Er is genoeg parkeergelegenheid op het eigen parkeerterrein achter het
                                restaurant. Daarnaast is het mogelijk om te parkeren bij de openbare parkeerplaats van
                                de Albert Heijn of de Lidl.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <div class="bottom-footer"></div>
        </div>
);
}

export default Menu;
