import './Menu.css';
import './About.css';
import './Header.css';
import './Footer.css';
import './Fonts/style.css'
import phone from './Images/phone.png';
import logo from './Images/Primair-logo-Paros.png';
import paros from './Images/Grieks-Restaurant-Paros.jpg';
import paros2 from './Images/Grieks-Restaurant-Paros2.jpg';
import terras from './Images/Grieks-Restaurant-Paros-Terras.jpg';
import terras2 from './Images/Grieks-Restaurant-Paros-Terras2.jpg';
import React, {useState, useEffect} from "react";
import { Helmet } from 'react-helmet';



const OverParos = () => {
    return (
        <div className="App">
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="robots" content="index, follow"/>
                <meta name="description" content="wat onze gasten van paros vinden. Typisch Grieks restaurant met bijzonder vers en perfect bereide schotels. Ruim voldoende op je bord. Het gegrilde vlees ..."/>
                <title>Grieks Restaurant Paros | Het restaurant waar je écht even kunt relaxen.</title>
                <link rel="icon" href="./Images/Primair_logo_Paros.ico" type="image/x-icon"/>
                <link rel="apple-touch-icon" sizes="180x180" href="Images/Primair-logo-Paros.png"/>
                <link rel="shortlink" href="https://restaurant-paros-echt.nl/"/>
                <link rel="canonical" href="https://restaurant-paros-echt.nl/"/>
                <meta name="revisit-after" content="7 days"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </Helmet>
            <header>
                <div className="top-header">
                    <div className="width-div">
                        <p>
                            <img alt="Grieks Restaurant Paros" src={phone} width="12px"/>
                            <span className="top-header-span">
                                Reserveer je plek via
                                <a href="tel:0475 487 814"> 0475 487 814</a>
                            </span>
                        </p>
                    </div>
                </div>
                <div className="main-header">
                    <nav>
                        <ul className="nav">
                            <li>
                                <a aria-current="page" href="./">home</a>
                            </li>
                            <li>
                                <a href="./OverParos">over paros</a>
                            </li>
                            <li>
                                <a href="./">
                                    <img width="60px" alt="Grieks Restaurant Paros" id="logo"
                                         src={logo}/>
                                </a>
                            </li>
                            <li>
                                <a href="./Menu">menu</a>
                            </li>
                            <li>
                                <a href="./Contact">contact</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            <div className="main">
                <div className="snowfall" id="snowfall"></div>
                <div className="top-space"></div>
                <div className="space"></div>
                <h6>greek food is always a great idea</h6>
                <div className="space"></div>
                <div className="menu">
                    <div className="menu-left">
                        <img className="about-image" src={terras2}/>
                    </div>
                    <div className="menu-right">
                        <h1 className="about-title">over paros</h1>
                        <div className="about">
                            <p className="about-text">Even op vakantie naar Griekenland!? Een avondje helemaal
                                ontspannen!? Geen probleem!<br/>
                                    Bij Grieks Restaurant Paros ervaar je het ultieme vakantiegevoel. Niks is beter dan
                                    genieten van onze specialiteiten en mooie wijnen onder het genot van gezellige
                                    Griekse muziek op de achtergrond.</p>
                            <p className="about-text">Al meer dan 20 jaar is Grieks Restaurant Paros een begrip in Echt
                                en omstreken. Grieks Restaurant Paros is dé plek voor jou om even te ontsnappen van de
                                drukte. Samen met het hele team van Paros toveren we graag een lach op uw gezicht en
                                staan we met veel enthousiasme en een Ouzo klaar om u te verwelkomen. Even een avond
                                voor jezelf, met je gezin, je vriend of je partner. Gegarandeerd genieten!</p>
                            <a className="about-button" href="./Menu">Bekijk het menu</a>
                        </div>
                    </div>
                </div>
                <div className="space"></div>
                <div className="bottom-images">
                    <img alt="Grieks Restaurant Paros" src={paros2}/>
                    <img alt="Grieks Restaurant Paros" src={terras}/>
                    <img alt="Grieks Restaurant Paros" src={paros}/>
                </div>
                <div className="space"></div>
            </div>
            <footer>
                <div className="main">
                    <div className="main-footer">
                        <div className="main-f-c">
                            <h4 className="footer-title">OPENINGSTIJDEN</h4>
                            <p>
                                Maandag: gesloten<br/>
                                Dinsdag: gesloten<br/>
                                Woensdag: 17:00 - 22:00<br/>
                                Donderdag: 17:00 - 22:00<br/>
                                Vrijdag: 17:00 - 22:00<br/>
                                Zaterdag: 17:00 - 22:00<br/>
                                Zondag: 15:00 - 22:00<br/>
                            </p>
                        </div>
                        <div className="main-f-c">
                            <h4 className="footer-title">CONTACT</h4>
                            <p>
                                TEL: 0475 487 814
                            </p>
                        </div>
                        <div className="main-f-c">
                            <h4 className="footer-title">BEREIKBAARHEID</h4>
                            <p>
                                Grieks restaurant Paros is gemakkelijk bereikbaar met de auto, fiets of het openbaar
                                vervoer. Er is genoeg parkeergelegenheid op het eigen parkeerterrein achter het
                                restaurant. Daarnaast is het mogelijk om te parkeren bij de openbare parkeerplaats van
                                de Albert Heijn of de Lidl.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <div class="bottom-footer"></div>
        </div>
    );
}

export default OverParos;
