import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initializeApp } from "firebase/app";
import reportWebVitals from './reportWebVitals';
import firebaseConfig from './firebaseConfig.js';
import { getDatabase, ref, set, onValue } from "firebase/database";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

reportWebVitals();


