import './Header.css';
import './Footer.css';
import './Contact.css';
import './Fonts/style.css'
import phone from './Images/phone.png';
import logo from './Images/Primair-logo-Paros.png';
import buiten from './Images/Grieks-Restaurant-Paros-buitenplaats.jpg';
import React, {useState, useEffect} from "react";
import { Helmet } from 'react-helmet';

const Contact = () => {
    return (
        <div className="App">
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="robots" content="index, follow"/>
                <meta name="description" content="wat onze gasten van paros vinden. Typisch Grieks restaurant met bijzonder vers en perfect bereide schotels. Ruim voldoende op je bord. Het gegrilde vlees ..."/>
                <title>Grieks Restaurant Paros | Het restaurant waar je écht even kunt relaxen.</title>
                <link rel="icon" href="./Images/Primair_logo_Paros.ico" type="image/x-icon"/>
                <link rel="apple-touch-icon" sizes="180x180" href="Images/Primair-logo-Paros.png"/>
                <link rel="shortlink" href="https://restaurant-paros-echt.nl/"/>
                <link rel="canonical" href="https://restaurant-paros-echt.nl/"/>
                <meta name="revisit-after" content="7 days"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="stylesheet" href="Contact.css"/>
            </Helmet>
            <header>
                <div className="top-header">
                    <div className="width-div">
                        <p>
                            <img alt="Grieks Restaurant Paros" src={phone} width="12px"/>
                            <span className="top-header-span">
                                Reserveer je plek via
                                <a href="tel:0475 487 814"> 0475 487 814</a>
                            </span>
                        </p>
                    </div>
                </div>
                <div className="main-header">
                    <nav>
                        <ul className="nav">
                            <li>
                                <a aria-current="page" href="./">home</a>
                            </li>
                            <li>
                                <a href="./OverParos">over paros</a>
                            </li>
                            <li>
                                <a href="./">
                                    <img width="60px" alt="Grieks Restaurant Paros" id="logo"
                                         src={logo}/>
                                </a>
                            </li>
                            <li>
                                <a href="./Menu">menu</a>
                            </li>
                            <li>
                                <a href="./Contact">contact</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            <div className="main">
                <div className="top-space"></div>
                <div className="space"></div>
                <h6>the best memories, are made around the table</h6>
                <div className="space"></div>
                <div className="menuc">
                    <div className="menu-leftc">

                        <p className="about-text">Het restaurant is geopend van woensdag tot en met zondag van 17:00 tot
                            22:00 (op zondag openen wij al om 15:00). Na 17:00 voor vandaag reserveren? Bel dan even
                            zodat we de mogelijkheden kunnen bekijken. </p>
                        <p className="about-text"><b>Telefoonnummer:</b> +31 0475 48 78 14</p>
                        <p className="about-text"><b>Adres:</b> Houtstraat 13, 6102 BD Echt</p>
                    </div>
                    <div className="menu-rightc">
                        <img className="about-image" alt="Grieks Restaurant Paros"
                             src={buiten}/>
                    </div>
                </div>
                <div className="space"></div>
            </div>
            <footer>
                <div className="main">
                    <div className="main-footer">
                        <div className="main-f-c">
                            <h4 className="footer-title">OPENINGSTIJDEN</h4>
                            <p>
                                Maandag: gesloten<br/>
                                Dinsdag: gesloten<br/>
                                Woensdag: 17:00 - 22:00<br/>
                                Donderdag: 17:00 - 22:00<br/>
                                Vrijdag: 17:00 - 22:00<br/>
                                Zaterdag: 17:00 - 22:00<br/>
                                Zondag: 15:00 - 22:00<br/>
                            </p>
                        </div>
                        <div className="main-f-c">
                            <h4 className="footer-title">CONTACT</h4>
                            <p>
                                TEL: 0475 487 814
                            </p>
                        </div>
                        <div className="main-f-c">
                            <h4 className="footer-title">BEREIKBAARHEID</h4>
                            <p>
                                Grieks restaurant Paros is gemakkelijk bereikbaar met de auto, fiets of het openbaar
                                vervoer. Er is genoeg parkeergelegenheid op het eigen parkeerterrein achter het
                                restaurant. Daarnaast is het mogelijk om te parkeren bij de openbare parkeerplaats van
                                de Albert Heijn of de Lidl.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <div class="bottom-footer"></div>
        </div>
    );
}

export default Contact;
